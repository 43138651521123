import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";
import { DialogTypeEnum } from "src/app/classes/enums";
import { IDialogData } from "src/app/interfaces/interfaces";
import { IconService } from "src/app/services/icon.service";
import { DialogCookieComponent } from "./dialog-cookie.component";

export const dialogComponentList = [DialogCookieComponent];

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
})
export class DialogComponent implements OnInit {
  public DialogTypeEnum: typeof DialogTypeEnum = DialogTypeEnum;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    public pagingService: PagingService,
    public iconService: IconService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
  ) {}
  htmlContent: string;

  ngOnInit(): void {}
  onClose(): void {
    this.dialogRef.close();
  }
}
