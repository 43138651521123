import { Component, Input } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-information-content",
  templateUrl: "./information-content.component.html",
})
export class InformationContentComponent {
  @Input({ required: true }) title!: string;
  constructor(public pagingService: PagingService) {}
}
