import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
  Event,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  hotjarInitializer,
  IHotjarSettings,
  NGX_HOTJAR_SETTINGS_TOKEN,
  WINDOW,
} from "ngx-hotjar";
import { Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { routeChangeAnimation } from "./animations/slideIn";
import { ConsentService } from "./services/consent.service";
import { FormService } from "./services/form.service";
import { IconService } from "./services/icon.service";
import { MappingService } from "./services/mapping.service";
import { PagingService } from "./services/paging.service";
import { TrackingService } from "./services/tracking.service";
import { FeatureAppService } from "./services/feature-app.service";
import { TotalPricePipe } from "./pipes/total-price.pipe";
import { BusinessProposalService } from "./services/business-proposal.service";

@Component({
  selector: "app-vbo-root",
  templateUrl: "./app.component.html",
  animations: [routeChangeAnimation],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  kill$ = new Subject<void>();
  public title = "service-booking-online";
  public hideSummary = true;

  @ViewChild("summaryContainer") summaryContainer: ElementRef;

  @ViewChild("routerContainer") routerContainer: ElementRef;

  constructor(
    public pagingService: PagingService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private mappingService: MappingService,
    private trackingService: TrackingService,
    private formService: FormService,
    private consentService: ConsentService,
    private businessProposalService: BusinessProposalService,
    private changeRef: ChangeDetectorRef,
    public iconService: IconService,
    public featureAppService: FeatureAppService,
    public totalPricePipe: TotalPricePipe,
    @Inject(NGX_HOTJAR_SETTINGS_TOKEN) private hotjarSettings: IHotjarSettings,
    @Inject(WINDOW) private win: Window,
    @Inject(DOCUMENT) private doc: Document,
  ) {
    this.pagingService.setFavicon();
    this.router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe((item) => {
        if (item instanceof NavigationEnd) {
          this.trackingService.trackUpdatedProducts(
            item.url,
            this.formService.allSelectedProducts,
          );
          this.trackingService.trackPageChange(
            location.href,
            this.translateService.instant(
              this.mappingService.stepNumberToI18nString(
                pagingService.formStepLocation,
              ) + ".header",
            ),
          );
          this.trackingService.updateCustomDimensions(
            this.formService.selectedWorkshop,
            this.formService.carInfoResponse,
            this.formService.milageValue,
            this.formService.serviceTypeSelection,
            this.formService.getBaseSelectionAsProducts(),
            this.formService.timeSelectionForm.controls.date?.value,
            this.formService.searchedByRegno,
            this.formService.selectedDropoffOption,
            this.formService.carInfoResponse?.fuelType,
            this.formService.insuranceProviderName,
          );
        }
      });
  }
  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
    if (
      !this.consentService.hasRequiredConsent &&
      !this.featureAppService.isFeatureApp
    ) {
      this.consentService.openConsentDialog((results) => {
        if (!!results) {
          this.trackingService.trackPageChange(
            location.href,
            this.translateService.instant(
              this.mappingService.stepNumberToI18nString(
                this.pagingService.formStepLocation,
              ) + ".header",
            ),
          );

          if (this.consentService.hotjarEnabled) {
            this.initHotjar();
          }
        }
      });
    } else if (this.consentService.hotjarEnabled) {
      this.initHotjar();
    }
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        const proposalId = params["proposal"];
        const rebrand = Boolean(params["rebrand"]);

        if (proposalId) {
          this.businessProposalService.initBusinessProposal(
            proposalId,
            rebrand,
          );
        }
      },
    });

    this.pagingService.setRouterContainerRef(this.routerContainer);
    this.pagingService.notifyIframeParent();
  }

  ngOnDestroy(): void {
    this.kill$.next();
    this.kill$.complete();
  }

  prepareRoute(outlet: RouterOutlet) {
    this.pagingService.setRouterContainerRef(this.routerContainer);
    this.pagingService.notifyIframeParent();
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState
    );
  }

  private initHotjar() {
    console.info("Initializing hotjar...");
    hotjarInitializer(this.hotjarSettings, this.doc, this.win)();

    // This replaces the NgxHotjarRouterModule.
    this.router.events
      .pipe(
        takeUntil(this.kill$),
        filter((e) => e instanceof NavigationEnd),
        map((ne) => ne as NavigationEnd),
      )
      .subscribe((event) => {
        const hj = (this.win as any).hj;
        if (hj) {
          hj("stateChange", event.urlAfterRedirects);
        }
      });
  }
}
