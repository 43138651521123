import { Location } from "@angular/common";
import { ElementRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { FeatureAppService } from "./feature-app.service";
import { BrandEnum } from "../classes/enums";

@Injectable({
  providedIn: "root",
})
export class PagingService {
  private routerContainerRef: ElementRef = null;
  private lastSentPageSize = 0;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private location: Location,
    private router: Router,
    private featureAppService: FeatureAppService,
  ) {}

  get brand(): BrandEnum | "default" {
    const featureAppBrand = this.featureAppService.getFeatureAppBrand();
    if (!!featureAppBrand) return featureAppBrand;
    const pathsList = this.location.path().split("/");
    if (pathsList.includes("vw")) return BrandEnum.vw;
    if (pathsList.includes("audi")) return BrandEnum.audi;
    if (pathsList.includes("seat")) return BrandEnum.seat;
    if (pathsList.includes("skoda")) return BrandEnum.skoda;
    if (pathsList.includes("cupra")) return BrandEnum.cupra;
    if (pathsList.includes("porsche")) return BrandEnum.porsche;
    if (this._document.location.host.includes("volkswagen"))
      return BrandEnum.vw;
    if (this._document.location.host.includes("skoda")) return BrandEnum.skoda;
    if (this._document.location.host.includes("seat")) return BrandEnum.seat;
    if (this._document.location.host.includes("audi")) return BrandEnum.audi;
    if (this._document.location.host.includes("cupra")) return BrandEnum.cupra;
    return "default";
  }

  get formStepLocation(): number {
    const step = parseInt(this.location.path().split("?")[0].slice(-1), 10);
    return !!step ? step : 1;
  }

  get isForm(): boolean {
    const step = parseInt(this.location.path().split("?")[0].slice(-1), 10);
    return !!step;
  }

  get nextStepUrl(): string {
    const step = this.formStepLocation;
    if (step === 8) {
      return this.getBasePath() + "/complete";
    } else {
      return this.getBasePath() + "/form/" + (step + 1) + "";
    }
  }

  get previousStepUrl(): string {
    const step = this.formStepLocation;
    if (step === 1) {
      return this.getBasePath() + "/form/1";
    } else {
      return this.getBasePath() + "/form/" + (step - 1) + "";
    }
  }

  get logoEnabled(): boolean {
    if (this.featureAppService.shouldHideLogo()) return false;
    return ["vw", "audi", "skoda", "seat", "cupra", "porsche"].includes(
      this.brand,
    );
  }

  navigateToStep(step: number): void {
    this.router.navigate([this.getBasePath() + "/form/" + step.toString()], {
      queryParamsHandling: "merge",
    });
  }

  navigateToServiceUnavailable(): void {
    this.router.navigate([this.getBasePath() + "/unavailable"]);
  }

  navigateToProposalRejected(): void {
    this.router.navigate([this.getBasePath() + "/rejected-proposal"]);
  }

  rebrand(brand: string): void {
    this.router.navigate(
      ["/" + this.featureAppService.getFeatureAppSubPath() + brand + "/1"],
      { queryParamsHandling: "merge" },
    );
  }

  navigateToExternalUrl(url: string): void {
    window.location.href = url;
  }

  setFavicon(): void {
    if (this.featureAppService.isFeatureApp) return;
    const favicon = this._document.getElementById("favicon");
    if (!favicon) return;
    favicon.setAttribute("href", "assets/favicons/" + this.brand + ".ico");
  }

  setRouterContainerRef(ref: ElementRef): void {
    if (!ref) {
      console.log("failed to set router container ref");
      return;
    }
    if (!this.routerContainerRef) console.log("set router container ref");
    this.routerContainerRef = ref;
  }

  notifyIframeParent(): void {
    if (!this.routerContainerRef) {
      console.log("no parent found, could not signal iframe");
      return;
    }
    if (
      this.routerContainerRef.nativeElement.scrollHeight == 0 ||
      this.lastSentPageSize ===
        this.routerContainerRef.nativeElement.scrollHeight
    )
      return;
    window.parent.postMessage(
      {
        message: "resize",
        top: this.routerContainerRef.nativeElement.scrollHeight + 240,
      },
      "*",
    );
    console.log(
      "iframe height change",
      this.routerContainerRef.nativeElement.scrollHeight,
    );
    this.lastSentPageSize = this.routerContainerRef.nativeElement.scrollHeight;
  }
  public getBasePath(): string {
    return (
      "/" + (this.featureAppService.getFeatureAppSubPath() ?? "") + this.brand
    );
  }
}
