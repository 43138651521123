import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import {
  PageFormIdEnum,
  businessProposalInitStep,
} from "src/app/classes/enums";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { MappingService } from "src/app/services/mapping.service";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public PageFormIdEnum = PageFormIdEnum;
  public isBusinessProposalActive = false;
  public businessProposalInitStep = businessProposalInitStep;

  constructor(
    public translate: TranslateService,
    public formService: FormService,
    public pagingService: PagingService,
    public mappingService: MappingService,
    public router: Router,
    public iconService: IconService,
    private businessProposalService: BusinessProposalService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    // the lang to use, if the lang isn't available, it will use the current loader to get them
  }
  ngOnInit(): void {
    this.subscription.add(
      this.businessProposalService.businessProposal$.subscribe({
        next: (proposal) => {
          this.isBusinessProposalActive = Boolean(proposal);
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onNavigate(step: number) {
    const isBusinessProposalIsActiveAndPrevStepOrNextStepInvalid =
      this.businessProposalService.isBusinessProposalActive &&
      (step < 4 ||
        (this.pagingService.formStepLocation > step &&
          !this.formService.form.get(
            this.mappingService.stepNumberToFormName(step > 1 ? step - 1 : 1),
          ).valid));
    const isNextStepInvalid =
      this.pagingService.formStepLocation <= step &&
      !this.formService.form.get(
        this.mappingService.stepNumberToFormName(step > 1 ? step - 1 : 1),
      ).valid;

    if (
      isBusinessProposalIsActiveAndPrevStepOrNextStepInvalid ||
      isNextStepInvalid
    ) {
      return;
    }

    this.router.navigate([this.pagingService.getBasePath() + "/form/", step]);
  }
}
