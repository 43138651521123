import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ClusterIconStyle } from "@angular/google-maps";
import { expandAnimation } from "src/app/animations/expand";
import { ILocation } from "src/app/interfaces/interfaces";
import { ImageResolverPipe } from "src/app/pipes/image-resolver.pipe";
import { IconService } from "src/app/services/icon.service";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  animations: [expandAnimation],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  public mapLocation: ILocation;
  public zoom = 10;
  public mapEnabled = true;

  public mapOptions = {
    clickableIcons: false,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    scrollwheel: true,
  };

  public clusterStyles: ClusterIconStyle[] = [
    {
      textColor: "black",
      url: this.imageResolver.transform("/assets/icons/map/cluster-48px.svg"),
      height: 48,
      width: 48,
      textSize: 12,
      backgroundPosition: "center 5px",
    },
  ];
  public noSelectionIcon = {
    url: this.imageResolver.transform("/assets/icons/map/no-place-24px.svg"),
    scaledSize: {
      width: 33,
      height: 33,
    },
  } as unknown as string;
  public selectedIcon = {
    url: this.imageResolver.transform(
      "/assets/icons/map/" + this.pagingService.brand + "/place-24px.svg"
    ),
    scaledSize: {
      width: 33,
      height: 33,
    },
  } as unknown as string;
  public clientIcon = {
    url: this.imageResolver.transform(
      "/assets/icons/map/" + this.pagingService.brand + "/client_location.svg"
    ),
    scaledSize: {
      width: 33,
      height: 33,
    },
  } as unknown as string;
  @Input() allLocations: ILocation[];
  @Input() clientLocation: ILocation;
  @Input() selectedLocation: ILocation;

  @Output() locationSelected = new EventEmitter<ILocation>();
  @Output() locationSearched = new EventEmitter<ILocation>();
  @Output() locationIdled = new EventEmitter<ILocation>();

  @ViewChild("search") public searchElementRef: ElementRef;

  constructor(
    private ngZone: NgZone,
    private pagingService: PagingService,
    public iconService: IconService,
    private imageResolver: ImageResolverPipe
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  init(): void {
    if (!this.selectedLocation)
      this.mapLocation = !!this.clientLocation
        ? this.clientLocation
        : this.allLocations[0];
    else this.mapLocation = this.selectedLocation;
  }

  ngAfterViewInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        location: {
          lat: this.mapLocation.latitude,
          lng: this.mapLocation.longitude,
        },
      },
      (results, status: string) => {
        if (status === "OK") {
          this.searchElementRef.nativeElement.value =
            results[0].formatted_address;
        }
      }
    );

    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // set latitude, longitude and zoom
        this.mapLocation = {
          longitude: place.geometry.location.lng(),
          latitude: place.geometry.location.lat(),
        };
        this.locationSearched.emit(this.mapLocation);
      });
    });
  }

  toggleMap(): void {
    this.mapEnabled = !this.mapEnabled;
  }

  onMarkerClick(location: ILocation): void {
    this.locationSelected.emit(location);
  }

  onMapIdle(): void {
    if (!this.mapLocation) return;
    this.locationIdled.emit(this.mapLocation);
  }

  useClientLocation(): void {
    if (!this.clientLocation) return;
    this.mapLocation = this.clientLocation;
  }

  onSearch(value: any): void {
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        return;
      }
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          placeId: predictions[0].place_id,
        },
        (responses) => {
          this.ngZone.run(() => {
            // set latitude, longitude and zoom
            this.searchElementRef.nativeElement.value =
              responses[0].formatted_address;
            this.mapLocation = {
              longitude: responses[0].geometry.location.lng(),
              latitude: responses[0].geometry.location.lat(),
            };
            this.locationSearched.emit(this.mapLocation);
          });
        }
      );
    });
  }

  locationMatches(location: ILocation, location2: ILocation): boolean {
    return JSON.stringify(location) === JSON.stringify(location2);
  }
}
