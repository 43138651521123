import { Component } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-footer-menu",
  templateUrl: "./footer-menu.component.html",
})
export class FooterMenuComponent {
  constructor(public pagingService: PagingService) {}
}
