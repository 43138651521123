import { environment } from "./../environments/environment";
import { AppRoutingModule } from "./module-imports/app-routing.module";
import { RouterModule } from "@angular/router";
import { TextBoxComponent } from "./components/inputs/text-box/text-box.component";
import { TextAreaComponent } from "./components/inputs/text-area/text-area.component";
import { SliderComponent } from "./components/inputs/slider/slider.component";
import { CheckboxComponent } from "./components/inputs/checkbox/checkbox.component";
import { ButtonComponent } from "./components/inputs/button/button.component";
import { BrowserModule } from "@angular/platform-browser";
import {
  ApplicationRef,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DoBootstrap,
  Injector,
  NgModule,
  isDevMode,
  NgZone,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { createCustomElement } from "@angular/elements";
import { AppComponent } from "./app.component";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DatePickerComponent } from "./components/inputs/date-picker/date-picker.component";
import { CheckboxCardComponent } from "./components/layout/checkbox-card/checkbox-card.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppAngularMaterialModule } from "./module-imports/angular-material.module";
import { AboutVehicleComponent } from "./components/views/about-vehicle/about-vehicle.component";
import { ChooseSpecificationsComponent } from "./components/views/choose-specifications/choose-specifications.component";
import { ProgressBarComponent } from "./components/layout/progress-bar/progress-bar.component";
import { DatePipe, LOCATION_INITIALIZED } from "@angular/common";
import { SectionTogglerComponent } from "./components/layout/section-toggler/section-toggler.component";
import { ExtraServicesComponent } from "./components/views/extra-services/extra-services.component";
import { SecondaryConfirmationBoxComponent } from "./components/layout/secondary-confirmation-box/secondary-confirmation-box.component";
import { ContactInformationComponent } from "./components/views/contact-information/contact-information.component";
import { CookieInformationComponent } from "./components/views/cookie-information/cookie-information.component";
import { TimeSelectionComponent } from "./components/views/time-selection/time-selection.component";
import { MapComponent } from "./components/layout/map/map.component";
import { ReviewComponent } from "./components/views/review/review.component";
import { CompleteComponent } from "./components/views/complete/complete.component";
import { DropdownComponent } from "./components/inputs/dropdown/dropdown.component";
import { SummaryComponent } from "./components/layout/summary/summary.component";
import { FooterComponent } from "./components/layout/footer/footer.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import {
  DialogComponent,
  dialogComponentList,
} from "./components/layout/dialog/dialog.component";
import { FloorPipe } from "./pipes/floor.pipe";
import { PricePipe } from "./pipes/price.pipe";
import { TotalpriceComponent } from "./components/layout/totalprice/totalprice.component";
import { MaintenanceComponent } from "./components/views/maintenance/maintenance.component";
import { InfoCardComponent } from "./components/layout/info-card/info-card.component";
import { TitleComponent } from "./components/layout/title/title.component";
import { LimiterPipe } from "./pipes/limiter.pipe";
import { RadioButtonComponent } from "./components/inputs/radio-button/radio-button.component";
import { PackageContainerComponent } from "./components/layout/package-container/package-container.component";
import { LoadingComponent } from "./components/layout/loading/loading.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { PackageInfoComponent } from "./components/layout/package-info/package-info.component";
import { RequestFailedComponent } from "./components/layout/request-failed/request-failed.component";
import { IHotjarSettings, NGX_HOTJAR_SETTINGS_TOKEN } from "ngx-hotjar";
import { NgxHotjarModule } from "src/app/module-imports/patched-hotjar.module";
import { IConfig, NgxMaskDirective, provideNgxMask } from "ngx-mask";
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { BrandCardComponent } from "./components/layout/brand-card/brand-card.component";
import { FirstToUpperPipe } from "./pipes/first-to-upper.pipe";
import { RegistrationNumberBoxComponent } from "./components/inputs/registration-number-box/registration-number-box.component";
import { WorkshopInfoComponent } from "./components/layout/workshop-info/workshop-info.component";
import { FaultReportComponent } from "./components/layout/fault-report/fault-report.component";
import { DamageReportComponent } from "./components/layout/damage-report/damage-report.component";
import { WindscreenWizardComponent } from "./components/layout/windscreen-wizard/windscreen-wizard.component";
import { ViewMoreComponent } from "./components/layout/view-more/view-more.component";
import { MatIconModule } from "@angular/material/icon";
import { IconComponent } from "./components/layout/icon/icon.component";
import { DiscountPricePipe } from "./pipes/discount-price.pipe";
import { ApplicationMessagesComponent } from "./components/layout/application-messages/application-messages.component";
import { PageContainerComponent } from "./components/layout/page-container/page-container.component";
import { ReplacePipe } from "./pipes/replace.pipe";
import { RegistrationNumberComponent } from "./components/inputs/registration-number/registration-number.component";
import { ChildControlPipe } from "./pipes/child-control.pipe";
import { InvalidInfoBoxComponent } from "./components/layout/invalid-info-box/invalid-info-box.component";
import { PriceDisplayPipe } from "./pipes/price-display.pipe";
import { OriginalServiceLinkPipe } from "./pipes/original-service-link.pipe";
import { RoadAssistantLinkPipe } from "./pipes/road-assistant-link.pipe";
import { BaseServiceLinkPipe } from "./pipes/base-service-link.pipe";
import { CarBrandPipe } from "./pipes/car-brand.pipe";
import { PriceDiffPipePipe } from "./pipes/price-diff-pipe.pipe";
import { PackageSummaryComponent } from "./components/layout/package-summary/package-summary.component";
import { FilterSubpackagesPipe } from "./pipes/filter-subpackages.pipe";
import { TimeslotDisplayPipe } from "./pipes/timeslot-display.pipe";
import { PackagePricePipe } from "./pipes/package-price.pipe";
import { BaseUrlInterceptor } from "./interceptors/baseUrl.interceptor";
import { ShouldShowSubmissionOptionsPipe } from "./pipes/should-show-submission-options.pipe";
import { TotalPricePipe } from "./pipes/total-price.pipe";
import { ImageResolverPipe } from "./pipes/image-resolver.pipe";
import { GoogleMapsModule } from "@angular/google-maps";
import { FindDealerComponent } from "./components/views/find-dealer/find-dealer.component";
import { WorkshopSystemSelectionComponent } from "./components/views/workshop-system-selection/workshop-system-selection.component";
import { PageBaseComponent } from "./components/base/page-base/page-base.component";
import { PackagesFilterPipe } from "./pipes/packages-filter.pipe";
import { PackagesServiceFilterPipe } from "./pipes/packages-service-filter.pipe";
import { BaseServicePackageInfoComponent } from "./components/layout/base-service-package-info/base-service-package-info.component";
import { ServiceAgreementPackageInfoComponent } from "./components/layout/service-agreement-package-info/service-agreement-package-info.component";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./classes/custom-overlay-container";
import { PackageProductMapPipe } from "./pipes/package-product-map.pipe";
import { GroupByPipe } from "./pipes/group-by.pipe";
import { RejectProposalDialogComponent } from "./components/layout/reject-proposal-dialog/reject-proposal-dialog.component";
import { RejectedProposalComponent } from "./components/views/rejected-proposal-success/rejected-proposal-success.component";
import { FeatureAppService } from "./services/feature-app.service";
import { ShowProposalPpsPackagesComponent } from "./components/views/show-proposal-pps-packages/show-proposal-pps-packages.component";

declare const FEATURE_APP_BASE_URL: string;
import { NgAddToCalendarModule } from "src/libs/ng-add-to-calendar";
import { FormService } from "./services/form.service";
import { PagingService } from "./services/paging.service";
import { PersonalDataComponent } from "./components/views/personal-data/personal-data.component";
import { TermsOfUseComponent } from "./components/views/terms-of-use/terms-of-use.component";
import { FooterMenuComponent } from "./components/layout/footer-menu/footer-menu.component";
import { FooterPopupComponent } from "./components/layout/footer-popup/footer-popup.component";
import { InformationContentComponent } from "./components/layout/information-content/information-content.component";
import { DevHelpers } from "./dev-helpers";
import { BusinessProposalService } from "./services/business-proposal.service";
import {
  IsFeatureAppDirective,
  IsNotFeatureAppDirective,
} from "./directives/is-feature-app.directive";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?cb=" + new Date().getTime()
  );
}
const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const DateFormats = {
  parse: {
    dateInput: ["YYYY-MM-DD"],
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DatePickerComponent,
    ButtonComponent,
    CheckboxComponent,
    DatePickerComponent,
    SliderComponent,
    TextAreaComponent,
    TextBoxComponent,
    CheckboxCardComponent,
    SummaryComponent,
    AboutVehicleComponent,
    WorkshopSystemSelectionComponent,
    FindDealerComponent,
    ChooseSpecificationsComponent,
    ProgressBarComponent,
    SectionTogglerComponent,
    FloorPipe,
    PricePipe,
    FooterComponent,
    ExtraServicesComponent,
    SecondaryConfirmationBoxComponent,
    ContactInformationComponent,
    CookieInformationComponent,
    PersonalDataComponent,
    TermsOfUseComponent,
    FooterMenuComponent,
    FooterPopupComponent,
    InformationContentComponent,
    IsFeatureAppDirective,
    IsNotFeatureAppDirective,
    TimeSelectionComponent,
    HeaderComponent,
    MapComponent,
    ReviewComponent,
    CompleteComponent,
    DropdownComponent,
    DialogComponent,
    ...dialogComponentList,
    TotalpriceComponent,
    MaintenanceComponent,
    InfoCardComponent,
    TitleComponent,
    LimiterPipe,
    RadioButtonComponent,
    PackageContainerComponent,
    LoadingComponent,
    PackageInfoComponent,
    RequestFailedComponent,
    BrandCardComponent,
    FirstToUpperPipe,
    RegistrationNumberBoxComponent,
    WorkshopInfoComponent,
    FaultReportComponent,
    DamageReportComponent,
    WindscreenWizardComponent,
    ViewMoreComponent,
    IconComponent,
    DiscountPricePipe,
    ApplicationMessagesComponent,
    PageContainerComponent,
    ReplacePipe,
    RegistrationNumberComponent,
    ChildControlPipe,
    InvalidInfoBoxComponent,
    PriceDisplayPipe,
    OriginalServiceLinkPipe,
    RoadAssistantLinkPipe,
    BaseServiceLinkPipe,
    CarBrandPipe,
    PriceDiffPipePipe,
    PackageSummaryComponent,
    FilterSubpackagesPipe,
    TimeslotDisplayPipe,
    PackagePricePipe,
    ShouldShowSubmissionOptionsPipe,
    TotalPricePipe,
    ImageResolverPipe,
    PageBaseComponent,
    PackagesFilterPipe,
    PackagesServiceFilterPipe,
    BaseServicePackageInfoComponent,
    ServiceAgreementPackageInfoComponent,
    PackageProductMapPipe,
    GroupByPipe,
    RejectProposalDialogComponent,
    RejectedProposalComponent,
    ShowProposalPpsPackagesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    MatIconModule,
    AppRoutingModule,
    HttpClientModule,
    AppAngularMaterialModule,
    ReactiveFormsModule,
    NgAddToCalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "se",
    }),
    NgxMaskDirective,
    NgxHotjarModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    GoogleMapsModule,
  ],
  providers: [
    {
      provide: NGX_HOTJAR_SETTINGS_TOKEN,
      useValue: {
        trackingCode: environment.hotjarId,
      } as IHotjarSettings,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, FeatureAppService],
      multi: true,
    },
    { provide: "googleTagManagerId", useValue: environment.gtmKey },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "sv_SE",
    },
    provideNgxMask(maskConfig),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    CarBrandPipe,
    DatePipe,
    PricePipe,
    TotalPricePipe,
    ImageResolverPipe,
    PackageProductMapPipe,
    PackagesFilterPipe,
    PackagesServiceFilterPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
  constructor(
    private injector: Injector,
    formService: FormService,
    pagingService: PagingService,
    packagesServiceFilterPipe: PackagesServiceFilterPipe,
    packagesFilterPipe: PackagesFilterPipe,
    zone: NgZone,
    businessProposalService: BusinessProposalService
  ) {
    if (isDevMode()) {
      window._devHelpers = new DevHelpers(
        formService,
        pagingService,
        packagesServiceFilterPipe,
        packagesFilterPipe,
        zone,
        businessProposalService
      );
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.mapsKey}&libraries=places&language=sv-SE&region=SE&callback=Function.prototype`;
    document.head.appendChild(script);
    const markerClusterScript = document.createElement("script");
    markerClusterScript.type = "text/javascript";
    markerClusterScript.src = `https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js`;
    document.head.appendChild(markerClusterScript);
  }
  ngDoBootstrap(appRef: ApplicationRef) {
    if (typeof FEATURE_APP_BASE_URL !== "undefined" && !!FEATURE_APP_BASE_URL) {
      if (!customElements.get("app-vbo-root")) {
        const customElement = createCustomElement(AppComponent, {
          injector: this.injector,
        });
        customElements.define("app-vbo-root", customElement);
      }
    } else {
      appRef.bootstrap(AppComponent);
    }
  }
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  faService: FeatureAppService
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const langToSet = "se";
        translate.setDefaultLang("se");
        translate.use(langToSet).subscribe({
          next: () => {
            console.log(`Successfully initialized '${langToSet}' language.'`);
          },
          error: () => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          complete: () => {
            if (!faService.isFeatureApp) {
              faService.initialize();
              resolve();
            } else {
              const intervalId = setInterval(() => {
                if (window["featureAppConfig"]) {
                  clearInterval(intervalId);
                  console.log("resolved fa config");
                  faService.initialize();
                  resolve();
                }
              }, 100);
            }
            resolve(null);
          },
        });
      });
    });
}
