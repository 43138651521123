import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Moment } from "moment";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { IconService } from "src/app/services/icon.service";
import { CalendarDayTimeSlotResponse } from "src/domain/client";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "sv_SE" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DatePickerComponent {
  @Input() availableDates: CalendarDayTimeSlotResponse[];
  @Input() selected?: Date;

  @ViewChild("calendar") calendar: MatCalendar<Moment>;

  @Output() selectedDate$ = new EventEmitter<Date>();

  exampleHeader = ExampleHeaderComponent;

  constructor() {}
  filterDates = (date: Moment | null): boolean => {
    return this.availableDates.some((d) => {
      return date.isSame(d.date, "day");
    });
  };

  onClick(date: Moment): void {
    this.selectedDate$.emit(date.toDate());
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: "app-datepicker-header",
  template: `
    <div class="date-picker-header bg-theme-info-secondary">
      <span class="hover" (click)="previousClicked()">
        <span class="material-icons">{{ iconService.arrowLeftIcon }}</span>
      </span>
      <span class="date-picker-header-label">{{ periodLabel }}</span>
      <span class="hover" (click)="nextClicked()">
        <span class="material-icons">{{ iconService.arrowRightIcon }}</span>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    public iconService: IconService,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
    calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    return this.dateAdapter.format(
      this.calendar.activeDate,
      this.dateFormats.display.monthYearA11yLabel,
    );
  }

  previousClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
      this.calendar.activeDate,
      -1,
    );
  }

  nextClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
      this.calendar.activeDate,
      1,
    );
  }
}
